/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import axios from 'axios'
import Cookies from 'js-cookie'
import { jwtDecode } from 'jwt-decode'
import * as Sentry from '@sentry/vue'
import helper from '~/global_helper/helpers.js'

const LOGOUT = 'LOGOUT'
const SAVE_TOKEN = 'SAVE_TOKEN'
const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE'
const SAVE_SIGN_UP_METADATA = 'SAVE_SIGN_UP_METADATA'
const AJAX_ERROR = 'AJAX_ERROR'

function getKeyFromToken(token) {
	if (!token) {
		return null
	}
	const userId = jwtDecode(token)?.sub
	return `su_${userId}`
}

// Each logged in account is given an account key in the following format `su_${userId}`
// When we go to login to a new account we track the last logged in key. So we can go back if we like.
// When we have a token being flagged as tmp, we add a flag so that when we change from that account we remove that account and data.
// To check if a user is logged in as an admin, we check if we are logged into a tmp account, and if one of our other accounts is an admin account.

// state
export const state = {
	user: null,
	token: Cookies.get('token'),
	current_account_key: Cookies.get('current_account_key') || getKeyFromToken(Cookies.get('token')),
	last_account_key: Cookies.get('last_account_key'),
	accounts: Object.keys(Cookies.get())
		.filter(key => /^su_.*/.test(key))
		.reduce((obj, key) => ({ ...obj, [key]: JSON.parse(Cookies.get(key) || '{}') }), {}),

	ajax_error: {
		message: '',
		errors: [],
	},
	signUpMetadata: {
		utm_source: Cookies.get('utm_source'),
		utm_medium: Cookies.get('utm_medium'),
		utm_campaign: Cookies.get('utm_campaign'),
		ref: Cookies.get('ref'),
	},
	subscribeFlags: {
		show_promo: Cookies.get('show_promo') === 'true',
		discount_code: Cookies.get('discount_code'),
		show_annual: Cookies.get('show_annual') === 'true',
		show_monthly: Cookies.get('show_monthly') === 'true',
	},
	hasActiveSubscription: false,
	sessionCountry: undefined,
}

export const getters = {
	user: state => state.user,
	token: state => state.token,
	addAccountFlag: state => state.current_account_key,
	current_account_key: state => state.current_account_key,
	last_account_key: state => state.last_account_key,
	// Will include all of the creators real accounts and tmp logged in accounts
	accounts: state => state.accounts,
	check: state => !!state.user,
	isAdmin: state => state.user && state.user.list_of_roles.indexOf(1) > -1,
	isAdminLoggedInAsUser: state => {
		// If currentely logged in account is a tmp account
		if (state.current_account_key && state.accounts[state.current_account_key]?.tmpLoginAsAdmin) {
			// If they have an admin account logged in
			return Object.keys(state.accounts).findIndex(key => key !== state.current_account_key && state.accounts[key]?.admin) > -1
		}
		return false
	},
	adminToken: state => {
		// Return token of any valid admin account
		const adminKey = Object.keys(state.accounts).find(key => state.accounts[key]?.admin)
		return adminKey ? state.accounts[adminKey].token : null
	},
	ajax_error: state => state.ajax_error,
	getSignUpMetadata: state => state.signUpMetadata,
	getSubscribeFlags: state => state.subscribeFlags,
	hasActiveSubscription: state => state.hasActiveSubscription,
	hasSubscriptionHistory: state => state.user?.has_subscription_history,
	niche: state => state.user?.tags['creator-niche']?.data['creator-niche'],
	hasSocialLinks: state => Object.keys(state.user?.data.socials).length > 0,
	sessionCountry: state => state.sessionCountry || state.user?.tags?.location?.data?.country,
}

// mutations
export const mutations = {
	// Add account flag is to track the account even after the user changes to another account
	[SAVE_TOKEN](state, { token, tmpLoginAsAdmin }) {
		const expires = new Date()
		expires.setDate(expires.getDate() + 30)
		state.token = token
		Cookies.set('token', token, { expires, path: '/' })

		// Clear accounts if we are not actively trying to login to a new account / Not an admin potentially logging into a new account
		const key = getKeyFromToken(token)

		if (key) {
			state.accounts[key] = {
				token,
				expires: expires.toISOString(),
				tmpLoginAsAdmin: tmpLoginAsAdmin || false,
			}

			// Update token
			state.last_account_key = state.current_account_key
			state.current_account_key = key

			// Update cookies
			Cookies.set(key, state.accounts[key], { expires, path: '/' })
			Cookies.set('last_account_key', state.last_account_key)
			Cookies.set('current_account_key', state.current_account_key)
		}
	},

	[SAVE_SIGN_UP_METADATA](state, payload) {
		state.signUpMetadata = { ...state.signUpMetadata, ...payload }
		Object.keys(payload).forEach(key => {
			if (payload[key]) {
				Cookies.set(key, payload[key])
				Cookies.set(key, payload[key], { domain: 'stanwith.me' })
				Cookies.set(key, payload[key], { domain: 'stan.store' })
			}
		})
	},

	setSubscribeFlags(state, payload) {
		state.subscribeFlags = { ...state.subscribeFlags, ...payload }
		Object.keys(payload).forEach(key => {
			if (payload[key]) {
				Cookies.set(key, payload[key])
				Cookies.set(key, payload[key], { domain: 'stanwith.me' })
				Cookies.set(key, payload[key], { domain: 'stan.store' })
			}
		})
	},

	switchAccount(state, key) {
		if (state.accounts[key]) {
			const expires = state.accounts[key].expires
			const token = state.accounts[key].token

			state.token = token
			Cookies.set('token', token, { expires: new Date(expires), path: '/' })

			// Check and clear last account if it was temporary
			if (state.accounts[state.current_account_key]?.tmpLoginAsAdmin) {
				delete state.accounts[state.current_account_key]
				Cookies.remove(state.current_account_key)
			} else {
				// Update old token
				state.last_account_key = state.current_account_key
			}
			state.current_account_key = key

			// Update cookies
			Cookies.set('last_account_key', state.last_account_key)
			Cookies.set('current_account_key', state.current_account_key)

			state.user = null
			return true
		}

		return false
	},

	prepareToAddAccount(state) {
		// All we need to add an account
		state.last_account_key = state.current_account_key
		state.current_account_key = null

		Cookies.set('last_account_key', state.last_account_key)
		Cookies.remove('current_account_key')
	},

	[FETCH_USER_SUCCESS](state, { user }) {
		state.user = user

		if (!state.user || !state.user?.subscriptions) {
			state.hasActiveSubscription = false
		} else {
			// eslint-disable-next-line no-underscore-dangle
			if (window._ctrack) {
				// eslint-disable-next-line no-underscore-dangle
				window._ctrack.identify({
					company_id: user.user_id, // Your Id of the company
				})
			}

			const platformSubscription = state.user?.subscriptions.platform
			const currentTimestamp = Math.floor(new Date().getTime() / 1000)
			const expiresSoon = platformSubscription ? platformSubscription.valid_until > currentTimestamp : false
			const hasActiveSubscription = platformSubscription && ([1, 2].includes(platformSubscription.status) || expiresSoon)
			state.hasActiveSubscription = !!hasActiveSubscription

			Sentry.setUser({ user_id: user.user_id, username: user.username })
		}

		// Check if current user is an admin
		const isAdmin = state.user && state.user?.list_of_roles.indexOf(1) > -1
		const key = `su_${user.user_id}`
		if (state.accounts[key]) {
			state.accounts[key].email = user.email
			state.accounts[key].fullName = user.full_name
			state.accounts[key].user_id = user.user_id
			state.accounts[key].username = user.username
			state.accounts[key].image = user?.data?.profile_image
			state.accounts[key].admin = isAdmin

			Cookies.set(key, state.accounts[key], { expires: new Date(state.accounts[key].expires), path: '/' })
		}
	},

	[FETCH_USER_FAILURE](state) {
		state.token = null
		state.hasActiveSubscription = false
		Cookies.remove('token')

		// Delete current users data
		delete state.accounts[state.current_account_key]
		Cookies.remove(state.current_account_key)
		Cookies.remove('current_account_key')

		state.last_account_key = null
		state.current_account_key = null
	},

	[LOGOUT](state) {
		state.user = null
		state.hasActiveSubscription = false
		state.token = null
		Cookies.remove('token')

		// Clear all signed in accounts
		Object.keys(state.accounts).forEach(key => Cookies.remove(key))
		Cookies.remove('last_account_key')
		Cookies.remove('current_account_key')

		state.accounts = {}
		state.last_account_key = null
		state.current_account_key = null
	},

	[AJAX_ERROR](state, payload) {
		const message = payload.message || payload.response?.data?.message
		const errors = payload.errors || payload.response?.data?.errors
		state.ajax_error = {
			message,
			errors,
		}
	},

	resetState(state) {
		state.ajax_error = { message: '', errors: [] }
	},

	setSessionCountry(state, country) {
		state.sessionCountry = country
	},
}

// actions
export const actions = {
	fetchSessionCountry({ commit }) {
		axios
			.get('v1/services/get-ip-info')
			.then(async response => {
				commit('setSessionCountry', response.data.country)
			})
			.catch(error => {
				// eslint-disable-next-line no-console
				console.log(`Could not automatically determine the country based on IP address. Error: ${error}`)
			})
	},
	login({ commit }, payload) {
		const customError = {}
		return new Promise((resolve, reject) => {
			axios
				.post('v1/login', payload)
				.then(response => {
					commit(SAVE_TOKEN, { token: response.data.token })
					resolve()
				})
				.catch(error => {
					if (error.response === undefined) {
						customError.message = 'Something went wrong'
						customError.errors = []
						customError.status = 'error'
						commit(AJAX_ERROR, customError)
					} else {
						commit(AJAX_ERROR, error)
					}
					reject(error)
				})
				.finally(() => {
					// commit('setLoading', false)
				})
		})
	},

	async fetchUser({ commit }) {
		let userData = null
		try {
			const { data: user } = await axios.get('v1/users/get_user')
			if (user.data.color_code) document.documentElement.style.setProperty('--stan-user-primary-color', user.data.color_code)
			if (user.profile_image) document.documentElement.style.setProperty('--stan-store-background-url', `url(${user.profile_image})`)
			commit(FETCH_USER_SUCCESS, { user })
			userData = user
		} catch (e) {
			commit(FETCH_USER_FAILURE)
		}
		return userData
	},

	updateProfile(_, payload) {
		// Prifile data updates as a payload dump and backend will take care of the rest
		const data = payload

		if (data.instagram) {
			data.instagram = helper.getSocialHandle(data.instagram, 'instagram.com')
			data.instagram = helper.getSocialHandle(data.instagram, '@')
		}

		if (data.tiktok) {
			data.tiktok = helper.getSocialHandle(data.tiktok, 'tiktok.com/@')
			data.tiktok = helper.getSocialHandle(data.tiktok, 'tiktok.com')
			data.tiktok = helper.getSocialHandle(data.tiktok, '@')
		}
		return axios.post('v1/users/profile-page-setup', data)
	},

	async logout({ commit }) {
		commit(LOGOUT)
	},

	raiseAjaxError({ commit }, payload) {
		commit(AJAX_ERROR, payload)
	},

	saveToken({ commit }, payload) {
		commit(SAVE_TOKEN, payload)
	},

	saveSignUpMetadata({ commit }, payload) {
		commit(SAVE_SIGN_UP_METADATA, payload)
	},

	saveSubscribeFlags({ commit }, payload) {
		commit('setSubscribeFlags', payload)
	},

	resetState: ({ commit }) => {
		commit('resetState')
	},
}
