// eslint-disable-next-line import/extensions
import store from '~/store'
import experiment from '~/plugins/experiments.js'
import commonHelper from '~/global_helper/helpers.js'

export default async (to, from, next) => {
	if (store.getters['Auth/check']) {
		const experimentConfig = {}
		let needUpdate = null
		store.dispatch('Auth/fetchUser').then(() => {
			const user = store.getters['Auth/user']
			// Experiment setup example
			// const experimentLaunchStoreButton = user.experiments?.launch_store_button
			// if (experimentLaunchStoreButton === undefined) {
			// 	experimentConfig.launch_store_button = commonHelper.isProbable(0.5) ? 'treatment' : 'control'
			// 	needUpdate = true
			// }
			needUpdate = false

			// First Product Publish Add Stan to Bio Experiment
			const firstProductPublishAddStanToBio = user.experiments?.first_product_publish_add_stan_to_bio
			if (firstProductPublishAddStanToBio === undefined) {
				experimentConfig.first_product_publish_add_stan_to_bio = commonHelper.isProbable(0.5) ? 'treatment' : 'control'
				needUpdate = true
			}

			// First Sale Experiment
			const firstSaleExperiment = user.experiments?.first_sale_experiment
			if (firstSaleExperiment === undefined) {
				experimentConfig.first_sale_experiment = commonHelper.isProbable(0.5) ? 'treatment' : 'control'
				needUpdate = true
			}

			// First 100 Store Visits (no sales) Experiment
			const first100StoreVisitsNoSales = user.experiments?.first_100_store_visits_no_sales
			if (first100StoreVisitsNoSales === undefined) {
				experimentConfig.first_100_store_visits_no_sales = commonHelper.isProbable(0.5) ? 'treatment' : 'control'
				needUpdate = true
			}

			// Stuck at Below 100 Store Visits Experiment
			const stuckAtBelow100StoreVisits = user.experiments?.stuck_at_below_100_store_visits
			if (stuckAtBelow100StoreVisits === undefined) {
				experimentConfig.stuck_at_below_100_store_visits = commonHelper.isProbable(0.5) ? 'treatment' : 'control'
				needUpdate = true
			}

			// CS: Zero Referrals Experiment
			const zeroReferralsExperiment = user.experiments?.zero_referrals_experiment
			if (zeroReferralsExperiment === undefined) {
				experimentConfig.zero_referrals_experiment = commonHelper.isProbable(0.5) ? 'treatment' : 'control'
				needUpdate = true
			}

			// CS: First Referral Experiment
			const firstReferralExperiment = user.experiments?.first_referral_experiment
			if (firstReferralExperiment === undefined) {
				experimentConfig.first_referral_experiment = commonHelper.isProbable(0.5) ? 'treatment' : 'control'
				needUpdate = true
			}

			// CS: Third Referral Experiment
			const thirdReferralExperiment = user.experiments?.third_referral_experiment
			if (thirdReferralExperiment === undefined) {
				experimentConfig.third_referral_experiment = commonHelper.isProbable(0.5) ? 'treatment' : 'control'
				needUpdate = true
			}

			// CS: Fifth Referral Experiment
			const fifthReferralExperiment = user.experiments?.fifth_referral_experiment
			if (fifthReferralExperiment === undefined) {
				experimentConfig.fifth_referral_experiment = commonHelper.isProbable(0.5) ? 'treatment' : 'control'
				needUpdate = true
			}

			// CS 4: Milestone (First Product) V2
			const milestoneFirstProductV2 = user.experiments?.milestone_first_product_v2
			if (milestoneFirstProductV2 === undefined) {
				experimentConfig.milestone_first_product_v2 = commonHelper.isProbable(0.5) ? 'treatment' : 'control'
				needUpdate = true
			}

			// CS 4: First Lead (v2)
			const firstLeadV2 = user.experiments?.first_lead_v2
			if (firstLeadV2 === undefined) {
				experimentConfig.first_lead_v2 = commonHelper.isProbable(0.5) ? 'treatment' : 'control'
				needUpdate = true
			}

			// CS 6: Marketing Examples (<100 visits lifetime)
			const marketingExamples100Visits = user.experiments?.marketing_examples_100_visits
			if (marketingExamples100Visits === undefined) {
				experimentConfig.marketing_examples_100_visits = commonHelper.isProbable(0.5) ? 'treatment' : 'control'
				needUpdate = true
			}

			// CS: How to Earn More (No Store Visits Last 30d)
			const howToEarnMoreNoStoreVisits = user.experiments?.how_to_earn_more_no_store_visits
			if (howToEarnMoreNoStoreVisits === undefined) {
				experimentConfig.how_to_earn_more_no_store_visits = commonHelper.isProbable(0.5) ? 'treatment' : 'control'
				needUpdate = true
			}

			// CS: How to Earn More (<100 Store Visits Last 30d)
			const howToEarnMoreBelow100StoreVisits = user.experiments?.how_to_earn_more_below_100_store_visits
			if (howToEarnMoreBelow100StoreVisits === undefined) {
				experimentConfig.how_to_earn_more_below_100_store_visits = commonHelper.isProbable(0.5) ? 'treatment' : 'control'
				needUpdate = true
			}

			// CS: How to Earn More :moneybag:(0 GMV & 100+ Store Visits Last 30d)
			const howToEarnMoreAbove100StoreVisitsNoGmv = user.experiments?.how_to_earn_more_above_100_store_visits_no_gmv
			if (howToEarnMoreAbove100StoreVisitsNoGmv === undefined) {
				experimentConfig.how_to_earn_more_above_100_store_visits_no_gmv = commonHelper.isProbable(0.5) ? 'treatment' : 'control'
				needUpdate = true
			}

			// CS: How to Earn More (No products)
			const howToEarnMoreNoProducts = user.experiments?.how_to_earn_more_no_products
			if (howToEarnMoreNoProducts === undefined) {
				experimentConfig.how_to_earn_more_no_products = commonHelper.isProbable(0.5) ? 'treatment' : 'control'
				needUpdate = true
			}

			if (needUpdate) {
				experiment(experimentConfig)
				store.dispatch('Auth/fetchUser')
			}
		})
	}
	next()
}
