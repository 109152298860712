const AuthContainer = () => import('~/components/layouts/AuthContainer').then(m => m.default || m)
const moduleRoutes = {
	path: '/',
	name: 'AuthIndex',
	redirect: '/',
	component: AuthContainer,
	children: [
		{
			path: '/',
			name: 'login',
			component: () => import('./Login').then(m => m.default || m),
			beforeEnter: (to, from, next) => {
				if (Object.keys(from.query).length > 0) {
					const mergedQuery = { ...to.query, ...from.query }
					if (JSON.stringify(to.query) !== JSON.stringify(mergedQuery)) {
						next({ ...to, query: mergedQuery, replace: true })
					}
				} else {
					next()
				}
			},
		},
		{
			path: '/login',
			name: 'login_page',
			component: () => import('./Login').then(m => m.default || m),
		},
		{
			path: '/auth',
			name: 'auth',
			component: () => import('./Login').then(m => m.default || m),
		},
		{
			path: '/switch',
			name: 'switch_account',
			component: () => import('./SwitchAccount').then(m => m.default || m),
		},
		{
			path: '/forgot_password',
			name: 'forgot_password',
			component: () => import('./ForgotPassword').then(m => m.default || m),
		},
		{
			path: '/reset_password',
			name: 'reset_password',
			component: () => import('./ResetPassword').then(m => m.default || m),
		},
		{
			path: '/roadmap-affiliate-login',
			name: 'roadmap_affiliate_login',
			component: () => import('./RoadmapAffiliate').then(m => m.default || m),
		},
		{
			path: '/reset-password',
			name: 'reset-password',
			component: () => import('./ResetPassword').then(m => m.default || m),
		},
		{
			path: '/subscribe',
			name: 'subscribe',
			component: () => import('./Subscribe').then(m => m.default || m),
		},
		{
			path: '/register',
			name: 'register',
			component: () => import('./Register').then(m => m.default || m),
			beforeEnter: (to, from, next) => {
				if (Object.keys(from.query).length > 0) {
					const mergedQuery = { ...to.query, ...from.query }
					if (JSON.stringify(to.query) !== JSON.stringify(mergedQuery)) {
						next({ ...to, query: mergedQuery, replace: true })
					} else {
						next()
					}
				} else {
					next()
				}
			},
		},
		{
			path: '/complete-registration',
			name: 'complete-register',
			component: () => import('./CompleteRegistration').then(m => m.default || m),
			beforeEnter: (to, from, next) => {
				if (Object.keys(from.query).length > 0) {
					const mergedQuery = { ...to.query, ...from.query }
					if (JSON.stringify(to.query) !== JSON.stringify(mergedQuery)) {
						next({ ...to, query: mergedQuery, replace: true })
					}
				} else {
					next()
				}
			},
		},
	],
}

export default moduleRoutes
